<!-- 产品追溯表 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <!-- 产品编码  -->
      <FormItem>
        <span>产品编码：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model.trim="queryFrom.product_model_code" />
      </FormItem>
      <!-- 产品名称 -->
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" @on-change="productChange" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index + item.name">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 规格型号/SKU -->
      <FormItem class="marginLeft40">
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.model_name" :disabled="!queryFrom.product_id" clearable filterable multiple :max-tag-count="1" class="iviewIptWidth250">
          <Option v-for="(item, index) in skuList" :key="index" :value="item.model_name" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 批号 -->
      <FormItem class="marginLeft40">
        <span>批号：</span>
        <Input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.batch_number" />
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnSure marginLeft20">
          <span v-if="!Loagin" class="pageBtn finger btnSure" @click="exportFile">导出</span>
          <span v-if="Loagin" class="pageBtn finger btnSure">
            <Spin v-if="Loagin" style="display: inline-block; color: white;">
              <Icon type="ios-loading" class="demo-spin-icon-load"></Icon>
            </Spin>
          </span>
        </span>
        <!-- <span class="pageBtn finger btnReset marginLeft20" @click="exportFile">导出</span> -->
      </FormItem>
    </Form>
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :productList="listColumns" :productData="listData" :pages="queryFrom" :total="total" totalText="条记录" :isLoad="isLoad" @change-page="changePage"></Table>
    </div>
    <!-- 详情弹窗 -->
    <Modal v-model="details" :mask-closable="true" footer-hide width="47" class="modal">
      <p slot="header" class="header">产品详情</p>
      <div class="body">
        <p>采购信息</p>
        <div class="info" v-for="(item, index) in detailsData.purchase_info" :key="'purchase_info' + index">
          <div class="left">
            <div class="item">
              <div class="title">采购单号</div>
              <div>：{{ item.order_number }}</div>
            </div>
            <div class="item">
              <div class="title">供应商</div>
              <div>：{{ item.supplier_name }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">订单时间</div>
              <div>：{{ item.insert_time }}</div>
            </div>
            <div class="item">
              <div class="title">采购单价</div>
              <div v-if="item.unit_price">：¥ {{ item.unit_price }}</div>
              <div v-else>：{{ item.unit_price }}</div>
            </div>
          </div>
        </div>
        <p>入库信息</p>
        <div class="info" v-for="(item, index) in detailsData.receipt_info" :key="'receipt_info' + index">
          <div class="left">
            <div class="item">
              <div class="title">入库单号</div>
              <div>：{{ item.order_number }}</div>
            </div>
            <div class="item">
              <div class="title">入库人</div>
              <div>：{{ item.create_by }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">入库时间</div>
              <div>：{{ item.create_time }}</div>
            </div>
            <div class="item">
              <div class="title">入库数量</div>
              <div>：{{ item.quantity }}</div>
            </div>
          </div>
        </div>
        <p>销售信息</p>
        <div class="info" v-for="(item, index) in detailsData.sale_info" :key="'sale_info' + index">
          <div class="left">
            <div class="item">
              <div class="title">销售单号</div>
              <div>：{{ item.order_code }}</div>
            </div>
            <div class="item">
              <div class="title">客户</div>
              <div>：{{ item.customer }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">订单时间</div>
              <div>：{{ item.order_time }}</div>
            </div>
            <div class="item">
              <div class="title">销售单价</div>
              <div v-if="item.product_unit_price">：¥ {{ item.product_unit_price }}</div>
              <div v-else>：{{ item.product_unit_price }}</div>
            </div>
          </div>
        </div>
        <p>出库信息</p>
        <div class="info" v-for="(item, index) in detailsData.delivery_info" :key="'delivery_info' + index">
          <div class="left">
            <div class="item">
              <div class="title">出库单号</div>
              <div>：{{ item.order_number }}</div>
            </div>
            <div class="item">
              <div class="title">出库人</div>
              <div>：{{ item.create_by }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">出库时间</div>
              <div>：{{ item.create_time }}</div>
            </div>
            <div class="item">
              <div class="title">出库数量</div>
              <div>：{{ item.quantity }}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'productTraceabilitySheet',
  components: {
    Table,
  },
  data() {
    return {
      Loagin: false,
      tabloading: false,
      // 详情数据
      detailsData: {
        // 采购信息
        purchase_info: [],
        // 入库信息
        receipt_info: [],
        // 销售信息
        sale_info: [],
        // 出库信息
        delivery_info: [],
      },
      // 详情弹窗
      details: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 60,
          maxWidth: 90,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '条形码',
          key: 'bar_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 70,
        },
        {
          title: '储运条件',
          key: 'storage_condition',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '生产日期',
          key: 'production_date',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 80,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 70,
          maxWidth: 120,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.getDetailsData(param.row.batch_number)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 产品下拉
      productList: [],
      // SKU下拉
      skuList: [],
      // 加载中
      isLoad: false,
      // 总条数
      total: 0,
      // 查询参数
      queryFrom: {
        page: 1,
        rows: 10,

        product_id: undefined,
        model_name: '',
        batch_number: '',
        product_model_code: null,
      },
    }
  },
  mounted() {
    // this.getProductTrace()
    this.getProductManageSearchProduct()
  },
  methods: {
    // 导出
    exportFile() {
      if (this.Loagin) {
        return
      }
      this.Loagin = true
      this.$Message.warning('功能开发中...')
      this.Loagin = false
    },
    // 查询
    query() {
      this.queryFrom.page = 1
      this.getProductTrace()
    },
    // 产品编改变
    productChange(e) {
      this.skuList = []
      this.queryFrom.model_name = ''
      if (!e) return
      // 规格/SKU下拉
      this.$http.get(this.$api.productManageSearchProductCode, { product_id: String(this.queryFrom.product_id) }).then(res => {
        this.skuList = res.data
      })
    },
    // 产品下拉
    getProductManageSearchProduct() {
      this.$http.get(this.$api.productManageSearchProduct).then(res => {
        this.productList = res.data
      })
    },
    // 获取详情数据
    getDetailsData(batch_number) {
      this.$http.get(this.$api.productTraceDetail, { batch_number: batch_number }).then(res => {
        this.detailsData.purchase_info = res.data.purchase_info
        this.detailsData.receipt_info = res.data.receipt_info
        this.detailsData.sale_info = res.data.sale_info
        this.detailsData.delivery_info = res.data.delivery_info
        // 处理无数据情况
        if (!this.detailsData.purchase_info || this.detailsData.purchase_info.length == 0) {
          this.detailsData.purchase_info.push({
            order_number: '',
            supplier_name: '',
            insert_time: '',
            unit_price: '',
          })
        }
        if (!this.detailsData.receipt_info || this.detailsData.receipt_info.length == 0) {
          this.detailsData.receipt_info.push({
            order_number: '',
            create_by: '',
            create_time: '',
            quantity: '',
          })
        }
        if (!this.detailsData.sale_info || this.detailsData.sale_info.length == 0) {
          this.detailsData.sale_info.push({
            order_code: '',
            customer: '',
            order_time: '',
            product_unit_price: '',
          })
        }
        if (!this.detailsData.delivery_info || this.detailsData.delivery_info.length == 0) {
          this.detailsData.delivery_info.push({
            order_number: '',
            create_by: '',
            create_time: '',
            quantity: '',
          })
        }
        // 处理日期
        if (this.detailsData.purchase_info) {
          this.detailsData.purchase_info.forEach(item => {
            item.insert_time = item.insert_time ? this.$moment(item.insert_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          })
        }
        if (this.detailsData.receipt_info) {
          this.detailsData.receipt_info.forEach(item => {
            item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          })
        }
        if (this.detailsData.sale_info) {
          this.detailsData.sale_info.forEach(item => {
            item.order_time = item.order_time ? this.$moment(item.order_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          })
        }
        if (this.detailsData.delivery_info) {
          this.detailsData.delivery_info.forEach(item => {
            item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          })
        }
        // 显示弹窗
        this.details = true
      })
    },
    // 翻页
    changePage(e) {
      this.queryFrom.page = e
      this.getProductTrace()
    },
    // 获取数据
    getProductTrace() {
      this.tabloading = true
      if (this.queryFrom.model_name && this.queryFrom.model_name.length == 0) {
        delete this.queryFrom.model_name
      }
      this.$http.get(this.$api.productTrace, this.queryFrom, false).then(res => {
        this.tabloading = false
        this.listData = res.data.rows
        this.total = res.data.total
        // 日期处理
        this.listData.forEach(item => {
          item.production_date = item.production_date ? this.$moment(item.production_date * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          // 判断是录入的是年月还是年月日
          item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date) : ''
          item.valid_period = item.valid_period ? this.$moment(item.valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
          item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period) : ''
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.modal {
  color: #333333;
  .header {
    font-size: 18px;
    font-weight: bold;
  }
  .body {
    p {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .info {
      display: flex;
      .left {
        flex: 6;
      }
      .right {
        flex: 4;
      }
      .item {
        display: flex;
        font-size: 16px;
        line-height: 36px;
        margin-bottom: 20px;
        .title {
          min-width: 70px;
          text-align-last: justify;
        }
      }
    }
  }
}
</style>
